<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Cấu hình lý do bảo hành'">
          <template v-slot:preview>
            <div>
              <b-row>
                <!-- form input for warranty reason -->
                <b-col md="4">
                  <b-form-group>
                    <label>Tên nhóm lý do bảo hành</label>
                    <b-form-input
                      v-model="name"
                      :state="validateState('name')"
                      size="sm"
                      placeholder="Nhập tên nhóm lý do bảo hành"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-name-live-feedback"
                    >
                      Yêu cầu nhập tên nhóm lý do bảo hành.
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.name.maxLength"
                      id="input-name-max-length"
                    >
                      Tên nhóm lý do phải ít hơn 129 kí tự.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- form input for group warranty -->
                <b-col md="4">
                  <b-form-group>
                    <label>Tên danh mục nội bộ</label>
                    <Autosuggest
                      :suggestions="filteredInternalCateOptions"
                      :model="selectedInternalCate"
                      :placeholder="'danh mục nội bộ'"
                      :limit="200"
                      @change="onInputInternalCateChange"
                      @select="onSelected($event)"
                      :state="validateState('selectedInternalCate')"
                      errorMessages="Yêu cầu chọn danh mục nội bộ."
                      suggestionName="suggestionName"
                    />
                  </b-form-group>
                </b-col>
                <!-- form input for warranty reason status -->
                <b-col md="4">
                  <b-form-group>
                    <label>Trạng thái</label>
                    <b-form-select
                      size="sm"
                      v-model="status"
                      :options="[
                        {
                          id: null,
                          name: 'Chọn trạng thái',
                          disabled: true,
                        },
                        {
                          id: WARRANTY_REASON_GROUP_STATUS.ACTIVE,
                          name: 'Hoạt động',
                        },
                        {
                          id: WARRANTY_REASON_GROUP_STATUS.INACTIVE,
                          name: 'Không hoạt động',
                        },
                      ]"
                      class="select-style"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <h4>Danh sách lý do bảo hành</h4>
              <div class="separator separator-dashed my-5"></div>
              <b-button
                class="mb-4 font-weight-bolder"
                @click="addReason"
                size="sm"
                variant="primary"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
              <div class="text-danger text-error" v-if="$v.reasons.$error">
                Danh sách lý do buộc phải có ít một lý do
              </div>
              <b-table
                class="table-bordered table-hover col-md-6"
                :fields="fieldDetail"
                :items="reasons"
                id="my-table-detail"
              >
                <template v-slot:cell(name)="row">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style"
                    v-model="row.item.name"
                  ></b-input>
                </template>
                <template v-slot:cell(actions)="row">
                  <v-icon
                    small
                    class="text-danger text-center"
                    @click="deleteItemInArray(row.item.id)"
                    v-b-tooltip
                    title="Xóa"
                    >mdi-delete</v-icon
                  >
                </template>
              </b-table>
            </div>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <router-link to="/warranty-reason-config" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { v4 } from 'uuid';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  WARRANTY_REASON_GROUP_STATUS,
  WARRANTY_REASON_STATUS,
} from '@/utils/enum';
import {
  removeAccents,
  makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';

export default {
  mixins: [validationMixin],
  validations: {
    reasons: {
      required,
    },
    selectedInternalCate: {
      required,
    },
    name: {
      required,
      maxLength: maxLength(128),
    },
    deleted: {
      required,
    },
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lý do bảo hành', route: '/warranty-reason-config' },
      { title: 'Danh sách lý do bảo hành', route: '/warranty-reason-config' },
      { title: 'Thêm mới lý do bảo hành' },
    ]);
  },
  data() {
    return {
      WARRANTY_REASON_GROUP_STATUS,
      id: null,
      name: '',
      cateConfigGroup: '',
      deleted: 0,
      status: 0,
      selectedInternalCate: '',
      selectedInternalCateId: null,
      internalCateOptions: [],
      filteredInternalCateOptions: [],
      reasons: [],
      limit: 10,
      fieldDetail: [
        {
          key: 'name',
          label: 'Lý do',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '55%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
    };
  },
  created() {
    this.warrantyReasonId = this.$route.query.id;
    this.getListExternalCate();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSelected(option) {
      this.selectedInternalCateId = option.item.id;
      this.selectedInternalCate = option.item.name;
    },
    validateReasons() {
      let isValid = true;
      if (this.reasons.length > 0) {
        for (let index = 0; index < this.reasons.length; index++) {
          const element = this.reasons[index];
          if (element.name.trim() === '' || element.name.length < 5) {
            makeToastFaile('Vui lòng nhập lý do với ít nhất 5 ký tự!');
            isValid = false;
          } else {
            const item = this.reasons.find(
              (item) => item.name === element.name && item.id !== element.id,
            );
            if (item) {
              makeToastFaile(`Lý do ${element.name} đã tồn tại!`);
              isValid = false;
            }
          }
        }
      }

      return isValid;
    },
    onSubmit: function() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const isValidValidate = this.validateReasons();
      if (!isValidValidate) return;

      const data = {
        groupName: this.name,
        internalCategory: this.selectedInternalCateId,
        deleted: this.deleted,
        reasons: this.reasons,
        status: this.status,
      };
      ApiService.post('warranty-reason-config', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    newId() {
      return v4();
    },
    onInputInternalCateChange(text) {
      this.selectedExternalCate = text;

      const filteredData = this.internalCateOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredInternalCateOptions = [...filteredData];
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.reasons.length; i++) {
        if (this.reasons[i].id === id) {
          this.reasons.splice(i, 1);
        }
      }
    },
    addReason() {
      const isValid = this.validateReasons();
      if (!isValid) return;

      this.reasons.push({
        id: this.newId(),
        name: '',
        deleted: WARRANTY_REASON_STATUS.ACTIVE,
        status: WARRANTY_REASON_STATUS.ACTIVE,
      });
    },
    fetchWarrantyConfigGroup: async function() {
      await ApiService.query('warranty-reason-config/group').then(
        ({ data }) => {
          this.internalCateOptions = [];
          data.data.data.rows.forEach((element) => {
            let item = {
              id: element.id,
              name: element.name,
            };
            this.internalCateOptions.push(item);
          });

          this.filteredCateOptions = [...this.internalCateOptions];
        },
      );
    },
    getListExternalCate: function() {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('productExternalCategory', paramQuery).then(
        ({ data }) => {
          this.internalCateOptions = [];
          this.numberOfPage = data.data.total_page;
          data.data.list_caterogy.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '-' + element.name;
            } else if (element.level === 2) {
              name = '--' + element.name;
            } else if (element.level === 3) {
              name = '---' + element.name;
            } else if (element.level === 4) {
              name = '----' + element.name;
            } else if (element.level === 5) {
              name = '-----' + element.name;
            } else if (element.level === 6) {
              name = '------' + element.name;
            } else {
              name = element.name;
            }
            let item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.internalCateOptions.push(item);
          });
          this.filteredInternalCateOptions = [...this.internalCateOptions];
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  font-size: 0.9rem;
  font-weight: 400;
}
</style>
